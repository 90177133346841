
<template>
  <v-layout wrap column justify-start :class="!bMini ? 'viewLayoutWrapper MiniPadding' : 'viewLayoutWrapper'">
    <!-- Start title -->
    <div class="SectionTitle d-flex align-center">
      <v-btn icon fab small to="/admin/proyects">
        <v-icon style="font-size: 30px; color: black">
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <span> Nuevo proyecto </span>
      <v-spacer></v-spacer>
    </div>
    <!-- End title -->
    <!--#region Start search component -->

    <!-- <search-appointments :mdUp="mdUp" :smUp="smUp"> </search-appointments> -->

    <!-- #endregion search component -->

    <!-- #region add proyect component -->
    <add-proyect-content :mdUp="mdUp" :smUp="smUp" />

    <!-- #endregion add proyect component -->
  </v-layout>
</template>
<script>
export default {
  components: {
    AddProyectContent: () => import("../../components/views/Proyects/AddProyectContent"),
  },
  props: {
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
    bMini: Boolean,
  },
  computed: {
    sectionTxt() {
      return this.texts.proyects;
    },
  },
};
</script>
